import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { CertCategoryApi } from 'api/cert/certCategory';
import { CertProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const CertCategoryConfig = {
    routes: [
        {
            path: 'category',
            children: [
                {
                    path: '',
                    element: <CertProvider><List /></CertProvider>
                },
                {
                    path: 'create',
                    element: <CertProvider><Create /></CertProvider>
                },
                {
                    path: 'detail/:code',
                    element: <CertProvider><Edit /></CertProvider>
                }
            ]
        }
    ],
    API: CertCategoryApi
};

export default CertCategoryConfig;
