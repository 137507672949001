import React from 'react';
import LogoImg from 'assets/images/sti_logo.png';

const Logo = () => {
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={kiseLogo} alt="Berry" width="100" />
         *
         */
        <img src={LogoImg} alt="kise" width="200" /> // Use the imported image with the correct name
    );
};

export default Logo;
