import React, {createContext, useContext, useState} from 'react';

const NewsContext = createContext({page: 0, size: 10, searchWrd: '', exposureYn: ''});

const NewsProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', exposureYn: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <NewsContext.Provider value={value}>
            {children}
        </NewsContext.Provider>
    );
};

const useNewsContext = () => {
    return useContext(NewsContext);
};

export {NewsProvider, useNewsContext};
