import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { MembershipApi } from 'api/manage/membership';
import { UserProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const MembershipConfig = {
    routes: [
        {
            path: 'membership',
            children: [
                {
                    path: '',
                    element: <UserProvider><List /></UserProvider>
                },
                {
                    path: 'create',
                    element: <UserProvider><Create /></UserProvider>
                },
                {
                    path: 'modify/:code',
                    element: <UserProvider><Edit /></UserProvider>
                }
            ]
        }
    ],
    API: MembershipApi
};

export default MembershipConfig;
