import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const NewsBoardApi = createApi({
    reducerPath: 'NewsBoardApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['NewsBoard'],
    endpoints: (build) => ({
        getNewsBoard: build.query({
            query: (params) => `${URL.NEWS_BOARD_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getNewsBoardDetails: build.query({
            query: (params) => `${URL.NEWS_BOARD_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createNewsBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NEWS_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateNewsBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NEWS_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteNewsBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NEWS_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetNewsBoardQuery,
    useGetNewsBoardDetailsQuery,
    useCreateNewsBoardMutation,
    useUpdateNewsBoardMutation,
    useDeleteNewsBoardMutation
} = NewsBoardApi;
