import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Modal, Stack, OutlinedInput, Grid} from '@mui/material';
import DaumPostcode from 'react-daum-postcode';
import { useTranslation } from 'react-i18next';
const { Button } = require('components/index');

export const DaumInput = ({ data, setAddressValues }) => {
    const [open, setOpen] = useState(false);
    const [inputZipCodeValue, setInputZipCodeValue] = useState(data?.zip || '');
    const [inputAddressValue, setInputAddressValue] = useState(data?.addr || '');
    const [inputAddrEtcValue, setInputAddrEtcValue] = useState(data?.addrEtc || '');

    useEffect(() => {
        if (data) {
            data?.zip && setInputZipCodeValue(data?.zip || '');
            data?.addr && setInputAddressValue(data?.addr || '');
            data?.addrEtc && setInputAddrEtcValue(data?.addrEtc || '');
        }
        return () => {};
    }, [data]);

    const { t } = useTranslation();
    const onCompletePost = (data) => {
        setOpen(false);

        setInputZipCodeValue(data.zonecode || '');
        setInputAddressValue(data.address || '');

        setAddressValues({
            zip: data?.zonecode,
            addr: data?.address
        });
    };

    const postCodeStyle = {
        width: '400px',
        minHeight: '500px'
    };

    return (
        <Grid container display={'flex'}>
            <Stack flex flexDirection={'row'} alignItems={'center'}>
                <OutlinedInput
                    name={'zip'}
                    type="text"
                    size="small"
                    sx={{ width: '150px' }}
                    value={inputZipCodeValue}
                    inputprops={{ readOnly: true }}
                    disabled={true}
                />
                <Button size="small" sx={{ marginLeft: '10px' }} onClick={() => setOpen(!open)}>
                    {t('zip_code')} {t('search')}
                </Button>
            </Stack>
            <OutlinedInput
                value={inputAddressValue}
                name={'addr'}
                type="text"
                size="small"
                fullWidth
                sx={{ marginTop: '8px' }}
                inputprops={{ readOnly: true }}
                disabled={true}
            />
            <OutlinedInput
                name={'addrEtc'}
                value={inputAddrEtcValue}
                onChange={(e) => {
                    setInputAddrEtcValue(e.target.value);
                    setAddressValues({
                        ...data,
                        addrEtc: e.target.value
                    });
                }}
                type="text"
                size="small"
                placeholder={t('addrEtcInput')}
                fullWidth
                sx={{ marginTop: '8px' }}
            />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24
                    }}
                >
                    <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} />
                </Box>
            </Modal>
        </Grid>
    );
};
