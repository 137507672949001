const CODE = {
    RCV_SUCCESS             : "200", // 성공
    
    RCV_ERROR_AUTH_REQUIRED : "401", // 인증 오류
    RCV_ERROR_AUTH          : "403", // 인증 오류
    RCV_ERROR_DELETE        : "700", // 삭제 오류
    RCV_ERROR_SAVE          : "800", // 저장 오류
    RCV_ERROR_VALIDATION    : "900", // 입력 오류

    MODE_CREATE         : "create", // 등록 모드
    MODE_MODIFY         : "modify", // 수정 모드 
    MODE_READ           : "read",   // 읽기 모드
    MODE_REPLY          : "reply",  // 답글 모드
    
    DATE_YEAR           : "year",
    DATE_MONTH          : "month",
    DATE_DATE           : "date",
    DATE_WEEK           : "week",
    DATE_DAY            : "day",

    GROUP : {
        COUNTRY : "COUNTRY",
        POSITION_TYPE : "POSITION_TYPE",
        WORKER_LEVEL : "WORKER_LEVEL",
        VAT_TYPE: "VAT_TYPE",
        STATUS: "STATUS_TYPE",
        FREE_BOARD: "FREE_BOARD",
        REVIEW_BOARD: "REVIEW_BOARD"
    },

    LOGIN_USER: {
        admin: 'ROLE_ADMIN',
        manager: 'ROLE_MANAGER',
        developer: 'ROLE_DEVELOPER',
    },

    STATUS: {
        PROJECT:{
                OBJ:{
                
                    REGISTERED:{
                        VALUE:"R",
                        KR:"접수",
                        EN:"Registered"
                    },
                    ESTIMATED:{
                        VALUE:"E",
                        KR:"견적",
                        EN:"Estimated"

                    },
                    CONTRACT:{
                        VALUE:"C",
                        KR:"계약",
                        EN:"Contract"
                    },
                    PROGRESS:{
                        VALUE:"I",
                        KR:"진행중",
                        EN:"In progress"
                    },
                    DEVELOPMENT:{
                        VALUE:"D",
                        KR:"개발완료",
                        EN:"Development complete"
                    },
                    PAID:{
                        VALUE:"P",
                        KR:"결제완료",
                        EN:"Payment complete"
                    }
                },
                LIST :[
                    {
                        VALUE:"R",
                        KR:"접수",
                        EN:"Registered"
                    },
                    {
                        VALUE:"E",
                        KR:"견적",
                        EN:"Estimated"
            
                    },
                    {
                        VALUE:"C",
                        KR:"계약",
                        EN:"Contract"
                    },
                    {
                        VALUE:"I",
                        KR:"진행중",
                        EN:"In progress"
                    },
                    {
                        VALUE:"D",
                        KR:"개발완료",
                        EN:"Development complete"
                    },
                    {
                        VALUE:"P",
                        KR:"결제완료",
                        EN:"Payment complete"
                    }
                ], 
                CONSULTINGLIST :[
                    {
                        VALUE:"new",
                        KR:"전체",
                        EN:"All"
                    },
                    {
                        VALUE:"R",
                        KR:"접수",
                        EN:"Registered"
                    },
                    {
                        VALUE:"E",
                        KR:"견적",
                        EN:"Estimated"
            
                    },
                    {
                        VALUE:"C",
                        KR:"계약",
                        EN:"Contract"
                    }
                ], 
                CONDITION :[
                    {
                        VALUE:"username",
                        KR:"아이디",
                        EN:"Username "
                    },
                    {
                        VALUE:"companyName",
                        KR:"상호/이름",
                        EN:"CompanyName "
            
                    },
                    {
                        VALUE:"projectName",
                        KR:"프로젝트 제목",
                        EN:"ProjectName"
                    },
                    ],
                PAYMENTTYPE :[
                    {
                        VALUE:"D",
                        KR:"계약금",
                        EN:"Down payment"
                    },
                    {
                        VALUE:"M",
                        KR:"중도금",
                        EN:"Middle payment"
                    },
                    {
                        VALUE:"B",
                        KR:"잔금",
                        EN:"Balance"
                    },
                    ]
       
                },        
        },

    BOARD:{
        LIST :[
            {
                VALUE:"General post",
                KR:"일반 글",
                EN:"General post"
            },
            {
                VALUE:"Free post",
                KR:"자유 글",
                EN:"Free post"
            }
        ], 
    },

    WORKER:{
        STATUS: [
            {
                VALUE:"true",
                KR:"작업중",
                EN:"In process"
            },
            {
                VALUE:"false",
                KR:"작업 없음",
                EN:"No project"
            }
        ],
    },

    USER:{
        SEARCH:[
            {
                VALUE:"ALL",
                KR:"전체",
                EN:"All"
            },
            {
                VALUE:"name",
                KR:"이름",
                EN:"Name"
            },
            {
                VALUE:"username",
                KR:"아이디",
                EN:"ID"
            },
            {
                VALUE:"phone",
                KR:"연락처",
                EN:"Phone"
            },
            {
                VALUE:"personInCharge",
                KR:"담당자",
                EN:"Person in charge"
            },
            {
                VALUE:"companyName",
                KR:"상호명",
                EN:"Company name"
            },
            {
                VALUE:"registerNumber",
                KR:"사업자 등록번호",
                EN:"Company registration number "
            }
        ],
        PROJECTSTATUS :[
            {
                VALUE:"Registered",
                KR:"접수",
                EN:"Registered"
            },
            {
                VALUE:"Estimated",
                KR:"견적",
                EN:"Estimated"
    
            },
            {
                VALUE:"Contract",
                KR:"계약",
                EN:"Contract"
            },
            {
                VALUE:"INPROGRESS",
                KR:"진행중",
                EN:"In progress"
            },
            {
                VALUE:"DONE",
                KR:"개발완료",
                EN:"Development complete"
            },
            {
                VALUE:"PAID",
                KR:"결제완료",
                EN:"Payment complete"
            }
        ], 
    }

}

export default CODE;