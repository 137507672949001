import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { PaymentApi } from 'api/payment/payment';

const PaymentForm = Loadable(lazy(() => import('./form')));

const PaymentConfig = {
    routes: [
        {
            path: 'payment',
            children: [
                {
                    path: '',
                    element: <PaymentForm />
                }
            ]
        }
    ],
    API: PaymentApi
};

export default PaymentConfig;
