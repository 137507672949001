import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { EventBoardApi } from 'api/community/EventBoardApi';
import { EventProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const EventBoardConfig = {
    routes: [
        {
            path: 'event',
            children: [
                {
                    path: '',
                    element: <EventProvider><List /></EventProvider>
                },
                {
                    path: 'create',
                    element: <EventProvider><Create /></EventProvider>
                },
                {
                    path: 'modify/:code',
                    element: <EventProvider><Edit /></EventProvider>
                }
            ]
        }
    ],
    API: EventBoardApi
};

export default EventBoardConfig;
