import React, { lazy } from 'react';
import MainLayout from './components/layout/MainLayout';
import {
    AdminConfig,
    BannerConfig,
    CommonCodeConfig,
    FaqBoardConfig,
    PopupConfig,
    QnaConfig,
    ContactConfig,
    UserMenuConfig,
    AdminMenuConfig,
    SiteInfoConfig,
    SystemSettingConfig,
    RoleManageConfig,
    ContentMenuConfig,
    MailConfig,
    SendMailConfig,
    AccountSettingsConfig,
    MembershipConfig,
    ReviewConfig,
    NoticeBoardConfig,
    InquiryBoardConfig,
    StaffBoardConfig,
    PortfolioBoardConfig,
    CertBoardConfig,
    YoutubeBoardConfig,
    GalleryBoardConfig,
    NewsBoardConfig,
    EventBoardConfig,
    BoardMailConfig,
    CertCategoryConfig,
    PaymentConfig,
    OrderConfig
} from './views';
import MinimalLayout from 'components/layout/MinimalLayout';
import Loadable from 'components/loading/Loadable';
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/auth-forms/Login')));
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

export const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard/default',
            element: <Dashboard />
        },
        {
            path: 'user',
            children: [...UserMenuConfig.routes]
        },
        {
            path: 'admin',
            children: [...AdminMenuConfig.routes]
        },
        {
            path: 'cert',
            children: [...CertCategoryConfig.routes]
        },
        {
            path: 'pay',
            children: [...PaymentConfig.routes]
        },
        {
            path: 'order',
            children: [...OrderConfig.routes]
        },
        {
            path: 'member',
            children: [...AdminConfig.routes, ...AccountSettingsConfig.routes]
        },
        {
            path: 'manage',
            children: [...BannerConfig.routes, ...PopupConfig.routes, ...ContentMenuConfig.routes, ...MembershipConfig.routes]
        },
        {
            path: 'common',
            children: [...CommonCodeConfig.routes, ...MailConfig.routes, ...BoardMailConfig.routes, ...SendMailConfig.routes]
        },
        {
            path: 'community',
            children: [
                ...QnaConfig.routes,
                ...ContactConfig.routes,
                ...FaqBoardConfig.routes,
                ...ReviewConfig.routes,
                ...NoticeBoardConfig.routes,
                ...InquiryBoardConfig.routes,
                ...CertBoardConfig.routes,
                ...StaffBoardConfig.routes,
                ...PortfolioBoardConfig.routes,
                ...YoutubeBoardConfig.routes,
                ...GalleryBoardConfig.routes,
                ...NewsBoardConfig.routes,
                ...EventBoardConfig.routes
            ]
        },
        {
            path: 'settings',
            children: [...SiteInfoConfig.routes, ...SystemSettingConfig.routes, ...RoleManageConfig.routes]
        }
    ]
};

export const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login/',
            element: <AuthLogin3 />
        }
    ]
};
