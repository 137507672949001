import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography, styled, Avatar } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import i18next from 'i18next';
import { useLocation } from 'react-router';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //
const MenuListItemBtn = styled(ListItemButton)(({ theme, level }) => ({
    backgroundColor: level > 1 ? 'transparent !important' : 'inherit'
}));

const NavCollapse = ({ menu, level }) => {
    const currentLanguage = i18next.language;
    const theme = useTheme();
    const urls = useLocation();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        setOpen(false);
        setSelected(null);
        if (menu?.children) {
            menu?.children?.forEach((item) => {
                let pathUrl = urls?.pathname.indexOf(item?.menuUrl);

                if (pathUrl > -1) {
                    setSelected(item?.seq);
                    setOpen(true);
                }
            });
        }
    }, [urls, menu?.children]);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu?.seq : null);
    };

    const menuIcon = !(level > 1) ? (
        <Avatar
            variant="rounded"
            alt="item_sub_list"
            className="menu_icon"
            src={menu?.attachment?.url && `${process.env.REACT_APP_PM_CONTEXT_URL}${menu?.attachment?.url}`}
        />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === urls.pathname ? 8 : 6,
                height: selected === urls.pathname ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );
    return (
        <>
            <MenuListItemBtn
                level={level}
                selected={urls?.pathname.indexOf(menu?.menuUrl != '' && menu?.menuUrl) > -1}
                onClick={handleClick}
            >
                <ListItemIcon>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            variant={menu.children.filter((e) => e.menuUrl === urls.pathname).length > 0 ? 'gnb_active' : 'gnb'}
                            color="inherit"
                        >
                            {currentLanguage === 'kr' ? menu.menuNameKr : menu.menuName}
                        </Typography>
                    }
                />
                {open ? (
                    <IconChevronUp
                        stroke={4}
                        size="0.8rem"
                        style={{ marginTop: 'auto', marginBottom: 'auto', color: theme.palette.text.hint3 }}
                    />
                ) : (
                    <IconChevronDown
                        stroke={4}
                        size="0.8rem"
                        style={{ marginTop: 'auto', marginBottom: 'auto', color: theme.palette.text.hint3 }}
                    />
                )}
            </MenuListItemBtn>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1,
                            background: theme.palette.primary.light
                        }
                    }}
                >
                    {menu.children?.map((item, indexItem) =>
                        item.children?.length > 0 ? (
                            <NavCollapse key={item.menuUrl + indexItem} menu={item} level={level + 1} />
                        ) : (
                            <NavItem key={item.menuUrl + indexItem} item={item} level={level + 1} />
                        )
                    )}
                </List>
            </Collapse>
        </>
    );
};

export default NavCollapse;
