import React, { useCallback, useState } from 'react';
import DataTable from 'react-data-table-component';
import Loading from 'components/loading/Loading';
import { useTranslation } from 'react-i18next';

const ListDataTable = ({ options, setOptions, setSelectedRows, ...props }) => {
    const { t } = useTranslation();
    const [selectedRowsPerPage] = useState([]);

    const customStyles = {
        noData: {
            style: {
                marginBottom: '20px'
            }
        },
        rows: {
            style: {
                minHeight: '50px'
            }
        },
        cells: {
            style: {
                // borderRight: '1px solid #e3f2fd'
            }
        },
        head: {
            style: {
                minHeight: '50px',
                borderTop: '2px solid #1e88e5'
            }
        },
        headRow: {
            style: {
                backgroundColor: '#e3f2fd',
                fontSize: '15px',
                color: '#212529',
                minHeight: 'auto'
            }
        },
        headCells: {
            style: {
                padding: '8px',
                fontWeight: 600
            }
        }
    };

    //GRID EVENTS
    const handlePageChange = (page) => setOptions((prevState) => ({ ...prevState, page: page - 1 }));
    const handlePerRowsChange = (newPerPage, page) =>
        !props?.pagination ? {} : setOptions((prevState) => ({ ...prevState, page: page - 1, size: newPerPage }));

    const handleSelectedRowsChange = useCallback(
        (selectedRows) => {
            selectedRowsPerPage[options?.page] = selectedRows;
            setSelectedRows(selectedRowsPerPage);
        },
        [selectedRowsPerPage, options?.page]
    );

    const handleApplySelectedRows = (row) =>
        selectedRowsPerPage[options?.page]?.filter((selectedRow) => selectedRow.seq === row.seq).length > 0;

    const NoDataWithHeaders = ({ columns }) => (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead style={{ borderTop: '2px solid #1e88e5', borderLeft: '1px solid #e3f2fd' }}>
                <tr style={{ backgroundColor: '#e3f2fd', fontSize: '15px', color: '#212529', minHeight: 'auto' }}>
                    {columns?.map((item, i) => (
                        <th
                            key={i}
                            style={{
                                width: item?.width ? item?.width : 'unset',
                                borderRight: '1px solid #e3f2fd',
                                padding: '8px',
                                fontWeight: 600
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {item?.name}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody style={{ borderRight: '1px solid #e3f2fd', borderLeft: '1px solid #e3f2fd', borderBottom: '1px solid #e3f2fd' }}>
                <tr>
                    <td colSpan={columns?.length} style={{ textAlign: 'center' }}>
                        <p style={{ padding: '20px', fontWeight: '600' }}>검색 결과가 없습니다.</p>
                    </td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <DataTable
            progressComponent={<Loading />}
            customStyles={customStyles}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationRowsPerPageOptions={[5, 10, 20, 50]}
            sortServer
            cus
            noDataComponent={<NoDataWithHeaders columns={props?.columns} />}
            paginationComponentOptions={{
                rowsPerPageText: t('rows_per_page'),
                rangeSeparatorText: t('of')
            }}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSelectedRowsChange={(rows) => rows?.selectedCount > 0 && handleSelectedRowsChange(rows?.selectedRows)}
            selectableRowSelected={handleApplySelectedRows}
            {...props}
        />
    );
};

export default ListDataTable;
