import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const CertCategoryApi = createApi({
    reducerPath: 'CertCategoryApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['CertCategories'],
    endpoints: (build) => ({
        getCertCategories: build.query({
            query: (params) => `${URL.CERT_CATEGORY_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getCertCategoriesByType: build.query({
            query: (params) => `${URL.CERT_CATEGORY_TYPE_LIST}?${params}`,
            providesTags: ['LIST_TYPE']
        }),
        getCertCategory: build.query({
            query: (params) => `${URL.CERT_CATEGORY_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createCertCategory: build.mutation({
            query: (body) => ({
                url: `${URL.CERT_CATEGORY_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST', 'DETAIL', 'LIST_TYPE']
        }),
        updateCertCategory: build.mutation({
            query: (body) => ({
                url: `${URL.CERT_CATEGORY_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL', 'LIST_TYPE']
        }),
        deleteCertCategory: build.mutation({
            query: (body) => ({
                url: `${URL.CERT_CATEGORY_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL', 'LIST_TYPE']
        })
    })
});

export const {
    useGetCertCategoriesQuery,
    useGetCertCategoriesByTypeQuery,
    useCreateCertCategoryMutation,
    useUpdateCertCategoryMutation,
    useDeleteCertCategoryMutation,
    useGetCertCategoryQuery
} = CertCategoryApi;
