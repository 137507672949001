import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const MembershipApi = createApi({
    reducerPath: 'MembershipApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Membership'],
    endpoints: (build) => ({
        getMembership: build.query({
            query: (params) => `${URL.MEMBERSHIP_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getMembershipDetails: build.query({
            query: (params) => `${URL.MEMBERSHIP_LIST}/${params}`,
            providesTags: ['DETAIL']
        }),
        getMemberCertRates: build.query({
            query: (params) => `${URL.MEMBERSHIP_CERT_RATES}/${params}`,
            providesTags: ['USER_CERT_RATES']
        }),
        getMemberCompanyMemberList: build.query({
            query: (params) => `${URL.MEMBERSHIP_COMPANY_MEMBER_LIST}/${params}`,
            providesTags: ['USER_COMPANY_MEMBER_LIST']
        }),
        updateMemberCompanyMember: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_COMPANY_MEMBER_APPROVE_YN}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['USER_COMPANY_MEMBER_LIST']
        }),

        createMembership: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        updateMembership: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        updateMemberPaymentComplete: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_PAYMENT_COMPLETE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        updateUserIdentified: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_IDENTIFIED_NUM_CREATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteUserIdentified: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_IDENTIFIED_NUM_DELETE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),

        updateMemberCertRate: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_CERT_RATE_UPDATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['USER_CERT_RATES']
        }),
        deleteMembership: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_DELETE}/${body}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteMemberships: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIPS_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        }),

        // Financial
        getMembershipByYear: build.query({
            query: (params) => `${URL.FINANCIAL_LIST_BY_YEAR}?${params}`,
            providesTags: ['DETAIL']
        }),
        createFinancial: build.mutation({
            query: (body) => ({
                url: `${URL.FINANCIAL_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['DETAIL']
        }),
        updateFinancial: build.mutation({
            query: (body) => ({
                url: `${URL.FINANCIAL_UPDATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['DETAIL']
        }),

        // Self
        getSelfDiagnosis: build.query({
            query: (params) => `${URL.SELF_DIAGNOSIS}/${params}`,
            providesTags: ['SELF_LIST']
        }),
        createSelfDiagnosis: build.mutation({
            query: (body) => ({
                url: `${URL.SELF_DIAGNOSIS_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['SELF_LIST']
        }),
        updateSelfDiagnosis: build.mutation({
            query: (body) => ({
                url: `${URL.SELF_DIAGNOSIS_UPDATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['SELF_LIST']
        }),
        evaluationCompleteEmail: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_EVALUATION_EMAIL_COMPLETE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        eligibilityCompleteEmail: build.mutation({
            query: (body) => ({
                url: `${URL.MEMBERSHIP_ELIGIBILITY_EMAIL_COMPLETE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
    })
});

export const {
    useGetMembershipQuery,
    useGetMembershipByYearQuery,
    useGetMembershipDetailsQuery,
    useGetMemberCertRatesQuery,
    useCreateMembershipMutation,
    useUpdateMembershipMutation,
    useUpdateMemberCertRateMutation,
    useDeleteMembershipMutation,
    useDeleteMembershipsMutation,

    useUpdateMemberPaymentCompleteMutation,
    useUpdateUserIdentifiedMutation,
    useDeleteUserIdentifiedMutation,

    useGetMemberCompanyMemberListQuery,
    useUpdateMemberCompanyMemberMutation,

    useCreateFinancialMutation,
    useUpdateFinancialMutation,

    useGetSelfDiagnosisQuery,
    useCreateSelfDiagnosisMutation,
    useUpdateSelfDiagnosisMutation,

    useEvaluationCompleteEmailMutation,
    useEligibilityCompleteEmailMutation

} = MembershipApi;
