import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const PaymentApi = createApi({
    reducerPath: 'PaymentApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Payments'],
    endpoints: (build) => ({
        getPayment: build.query({
            query: () => `${URL.PAYMENT_DETAIL}`,
            providesTags: ['DETAIL']
        }),
        updatePayment: build.mutation({
            query: (body) => ({
                url: `${URL.PAYMENT_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['DETAIL']
        })
    })
});

export const { useUpdatePaymentMutation, useGetPaymentQuery } = PaymentApi;
