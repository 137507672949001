import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { OrderProvider } from './context';
import { OrderApi } from 'api/order/order';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const OrderConfig = {
    routes: [
        {
            path: '',
            children: [
                {
                    path: '',
                    element: (
                        <OrderProvider>
                            <List />
                        </OrderProvider>
                    )
                },
                {
                    path: 'create',
                    element: (
                        <OrderProvider>
                            <Create />
                        </OrderProvider>
                    )
                },
                {
                    path: 'detail/:code',
                    element: (
                        <OrderProvider>
                            <Edit />
                        </OrderProvider>
                    )
                }
            ]
        }
    ],
    API: OrderApi
};

export default OrderConfig;
