import React, { useState } from 'react';
import { Skeleton, Typography, styled } from '@mui/material';
import { forwardRef } from 'react';

const StyledTextArea = styled('textarea')({
    borderRadius: '3px',
    padding: '10px',
    width: '100%',
    height: '100px',
    rounded: '24px',
    border: '1px solid #c4c4c4',
    outline: '#c4c4c4',
    '&:hover': {
        border: '1px solid #616161',
        outline: '#616161'
    },
    '&:active': {
        border: '2px solid #1e96f3',
        outline: '#1e96f3'
    },
    '&:focus': {
        border: '2px solid #1e96f3',
        outline: '#1e96f3'
    },
    '&:focus-visible': {
        border: '2x solid #1e96f3',
        outline: '#1e96f3'
    }
});
export const FormTextAreaLabel = ({ defaultValue, max, ...props }) => {
    const [content, setContent] = useState(defaultValue || '');

    return props.loading ? (
        <Skeleton animation="wave" height={60} sx={{ borderRadius: '12px' }} />
    ) : (
        <>
            <StyledTextArea
                id="standard-multiline-flexible"
                name="content"
                initData={defaultValue}
                onChange={(e) => setContent(e.target.value)}
                value={content}
                placeholder={''}
                variant="standard"
                minRows={2}
                {...props}
            />
        </>
    );
};

