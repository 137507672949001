import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const OrderApi = createApi({
    reducerPath: 'OrderApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Orders'],
    endpoints: (build) => ({
        getOrder: build.query({
            query: (params) => `${URL.ORDER_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getOrderDetail: build.query({
            query: (params) => `${URL.ORDER_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        updateOrder: build.mutation({
            query: (body) => ({
                url: `${URL.ORDER_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteOrder: build.mutation({
            query: (body) => ({
                url: `${URL.ORDER_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        })
    })
});

export const { useGetOrderQuery, useGetOrderDetailQuery, useUpdateOrderMutation, useDeleteOrderMutation } = OrderApi;
