import React from 'react';
import { Button, CircularProgress, styled } from '@mui/material';

const BaseButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.hint2,
    backgroundColor: '#F7FAFF',
    border: '1px solid #e0e0e0',
    height: 41,
    minWidth: 150,
    fontWeight: 600,
    ...theme,
    "&:hover": {
        background: theme.palette.primary.light,
        color: theme.palette.text.hint2
    },
}));

const getSizeStyles = (size) => {
    const sizes = {
        sm: { minWidth: '50px', height: '35px', fontSize: '14px' },
        md: { minWidth: '100px', height: '40px', fontSize: '16px' },
        lg: { minWidth: '250px', height: '62px', fontSize: '18px' },
        xl: { minWidth: '300px', height: '72px', fontSize: '20px' }
    };

    return sizes[size] || {};
};

const CustomButton = ({ to, state, text, onClick, size, loading, ...props }) => {
    const style = {
        minWidth: '200px',
        height: '40px',
        fontSize: '16px',
        ...getSizeStyles(size)
    };

    return (
        <BaseButton
            type="button"
            style={style}
            onClick={onClick}
            {...props}
            startIcon={loading ? <CircularProgress size={20} color="light" /> : ''}
            disabled={loading}
        >
            {text}
        </BaseButton>
    );
};

export default CustomButton;
