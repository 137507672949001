import React from 'react';
import { Box, TableCell, styled } from '@mui/material';

const Cell = ({ children, ...props }) => {
    return (
        <StyledCell {...props}>
            <StyledCellBox {...props}>{children || ''}</StyledCellBox>
        </StyledCell>
    );
};

export default Cell;

const cellWidth = (size) => {
    const widthMapping = {
        mini: '50px',
        small: '100px',
        medium: '150px',
        large: '200px',

        w10: '10%',
        w15: '15%',
        w20: '20%',
        w30: '30%',
        w40: '40%',
        w50: '50%'
    };

    return widthMapping[size] || 'auto';
};

const StyledCell = styled(TableCell)(({ theme, ...props }) => ({
    width: props?.size ? cellWidth(props?.size) : 'auto',
    padding: props.padding || '10px 15px',
    borderRight: `1px solid #e0e0e0`,
    height: '100%',
    backgroundColor: props?.gray !== undefined ? '#f7faff' : '#fff',
    textAlign: 'center'
}));

const StyledCellBox = styled(Box)(({ theme, ...props }) => ({
    display: 'flex',
    alignItems: props?.start !== undefined ? 'start' : 'center',
    flexDirection: props?.flexdirection || 'row',
    gap: '5px',
    textAlign: 'left',
    justifyContent: props?.center !== undefined ? 'center' : 'start',
    whiteSpace: 'pre-line'
}));
