import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Grid } from '@mui/material';

import ProfileSection from './ProfileSection';

import { IconMenu2 } from '@tabler/icons';
import LanguageSection from './LanguageSection';
import {useGetSiteInfoQuery} from "../../../../views/pages/Settings/SiteInfo/SiteInfoApi";
import {SERVER_URL} from "config";
import React from "react";

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const { data: siteInfo, loading } = useGetSiteInfoQuery();

    return (
        <Grid container justifyContent={'space-between'}>
            <Grid item sx={{display: 'flex', alignItems: "center", gap: '30px'}}>
                <Box sx={{display: {xs: 'none', md: 'block'}, width: "200px"}}>
                    <img src={SERVER_URL + siteInfo?.content?.atchHeader?.url} alt="" style={{width:"100%"}}/>
                </Box>
                <ButtonBase sx={{overflow: 'hidden'}}>
                <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            '&:hover': {
                                background: theme.palette.primary.dark,
                                color: theme.palette.primary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Grid>
            <Grid item sx={{display: 'flex', alignItems: "center"}}>
                {/*<LanguageSection />*/}
                <ProfileSection />
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
