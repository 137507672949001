import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const EventBoardApi = createApi({
    reducerPath: 'EventBoardApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['EventBoard'],
    endpoints: (build) => ({
        getEventBoard: build.query({
            query: (params) => `${URL.EVENT_BOARD_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getEventBoardDetails: build.query({
            query: (params) => `${URL.EVENT_BOARD_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createEventBoard: build.mutation({
            query: (body) => ({
                url: `${URL.EVENT_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateEventBoard: build.mutation({
            query: (body) => ({
                url: `${URL.EVENT_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteEventBoard: build.mutation({
            query: (body) => ({
                url: `${URL.EVENT_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetEventBoardQuery,
    useGetEventBoardDetailsQuery,
    useCreateEventBoardMutation,
    useUpdateEventBoardMutation,
    useDeleteEventBoardMutation
} = EventBoardApi;
